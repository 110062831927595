import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import video from "./images/bg-video.mp4";
// import { initGA, trackPageView } from "./analytics";
import axios from "axios";
import ReactLoading from "react-loading";
import { primaryColor } from "./styles/colors";
// import { pdfjs } from "react-pdf";

// Specify the location of the PDF.js worker file
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const loading = (
  <div
    className="pt-3 text-center"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100vh",
    }}
  >
    <ReactLoading
      type={"spinningBubbles"}
      color={primaryColor}
      height={"70px"}
      width={"70px"}
    />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const OTP = React.lazy(() => import("./views/pages/login/otp"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const HomeScreen = React.lazy(() => import("./views/HomeScreen"));
const Page = React.lazy(() => import("./layout/Page"));
const BenefitScreen = React.lazy(() => import("./views/BenefitScreen"));
const Speakers = React.lazy(() => import("./views/Speakers"));
const FaqScreen = React.lazy(() => import("./views/FaqScreen"));
const MediaScreen = React.lazy(() => import("./views/MediaScreen"));
const ContactUs = React.lazy(() => import("./views/ContactUs"));
const AgendaScreen = React.lazy(() => import("./views/AgendaScreen"));
const IndustryTrends = React.lazy(() => import("./views/IndustryTrends"));
const SpeakerBio = React.lazy(() => import("./views/SpeakerBio"));
const SpeakerListing = React.lazy(() => import("./views/SpeakerListing"));
const VenueScreen = React.lazy(() => import("./views/VenueScreen"));
const SponsorsScreen = React.lazy(() => import("./views/SponsorsScreen"));
const BookingLanding = React.lazy(() => import("./views/BookingLanding"));
const PayOnline = React.lazy(() => import("./views/PayOnline"));
const SponsorLanding = React.lazy(() => import("./views/SponsorLanding"));
const BookingThankyou = React.lazy(() => import("./views/BookingThankyou"));
const AgendaPage = React.lazy(() => import("./views/Agenda"));
const SponsorBio = React.lazy(() => import("./views/SponsorBio"));
const ScrollOnTop = React.lazy(() => import("./components/ScrollOnTop"));
const Attendees = React.lazy(() => import("./views/Attendees"));
const SponsorsListing = React.lazy(() => import("./views/SponsorListing"));
const Slideshare = React.lazy(() => import("./views/Slideshare"));
const PageForm = React.lazy(() => import("./layout/PageForm"));
const PrivacyPolicy = React.lazy(() => import("./views/PrivacyPolicy"));
const RemindMeLater = React.lazy(() => import("./views/RemindMeLater"));
const Receipt = React.lazy(() => import("./components/Reciept"));
const TermsAndConditions = React.lazy(() =>
  import("./views/TermsAndConditions")
);
// const BookingForm = React.lazy(() => import("./views/BookingFormV2"));

const App = () => {
  const [assetsLoaded, setAssetsLoaded] = useState(true);
  const [gallery, setgallery] = useState();
  const [background, setbackground] = useState();
  useEffect(() => {
    fetchBackground();
    // getGoogleAnalyticsKey();
    // setTimeout(() => {
    //   initGA(key);
    //   trackPageView(window.location.pathname);
    // }, 1000);
  }, []);
  async function fetchBackground() {
    try {
      setAssetsLoaded(true);
      const result = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/background-image`
      );
      if (result.status == 200) {
        setbackground(result.data[0]);
        if (result?.data[0]?.background === "gallery") {
          fetchGalleryData();
        }
        setAssetsLoaded(false);
      }
    } catch (err) {
      console.log("Background api error", err);
    }
  }
  async function fetchGalleryData() {
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/background-gallery`
      );
      if (result.status === 200) {
        setAssetsLoaded(false);
        let tempGallery = [];
        let tempNum = 0;
        result.data.forEach((val, index) => {
          if (
            val?.backgroundGallery &&
            val?.backgroundGallery?.length > 0 &&
            tempNum < 4
          ) {
            tempNum++;
            let url = val?.backgroundGallery.replace(/\\/g, "/");
            tempGallery.push(url);
          }
        });
        setgallery(tempGallery);
      }
    } catch (err) {
      console.log("tickers api error", err);
    }
  }
  return (
    <Suspense fallback={loading}>
      <BrowserRouter basename="/">
        <Routes>
          {/* <ScrollOnTop /> */}
          {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
          <Route
            exact
            path="/admin/login"
            name="Login Page"
            element={<Login />}
          />
          <Route
            exact
            path="/admin/otp-verify"
            name="OTP Verify"
            element={<OTP />}
          />
          {/* <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
          <Route exact path="/receipt" name="Receipt" element={<Receipt />} />
          <Route
            exact
            path="/speakers"
            name="Speaker"
            element={
              <Page variant="white" routeName="Speakers">
                <Speakers />
              </Page>
            }
          />
          <Route
            exact
            path="/who-should-attend"
            name="Benefitcreen"
            element={
              <Page variant="white" routeName="Benefits">
                <BenefitScreen />
              </Page>
            }
          />
          <Route
            exact
            path="/faq"
            name="FAQScreen"
            element={
              <Page variant="white" routeName="FAQ">
                <FaqScreen />
              </Page>
            }
          />
          <Route
            exact
            path="/media"
            name="MediaScreen"
            element={
              <Page variant="white" routeName="Media">
                <MediaScreen />
              </Page>
            }
          />
          <Route
            exact
            path="/contact-us"
            name="ContactUs"
            element={
              <Page routeName="Contact Us" variant="white">
                <ContactUs />
              </Page>
            }
          />
          <Route
            exact
            path="/agenda"
            name="AgendaScreen"
            element={
              <Page variant="white" routeName="Program">
                <AgendaScreen />
              </Page>
            }
          />
          <Route
            exact
            path="/trend/:slug"
            name="IndustryTrends"
            element={
              <Page variant="white" routeName="Industry Trends">
                <IndustryTrends />
              </Page>
            }
          />
          <Route
            exact
            path="/speaker/:id"
            name="SpeakerBio"
            element={
              <Page variant="white" routeName="Speaker Profile">
                <SpeakerBio />
              </Page>
            }
          />
          <Route
            exact
            path="/featured-speakers"
            name="SpeakerListing"
            element={
              <Page variant="white" routeName="Featured Speakers">
                <SpeakerListing />
              </Page>
            }
          />
          <Route
            exact
            path="/venue"
            name="VenueScreen"
            element={
              <Page variant="white" routeName="Venue">
                <VenueScreen />
              </Page>
            }
          />
          <Route
            exact
            path="/sponsors"
            name="SponsorsScreen"
            element={
              <Page variant="white" routeName="Sponsors">
                <SponsorsScreen />
              </Page>
            }
          />
          <Route
            exact
            path="/sponsors-listing"
            name="Sponsors Listing"
            element={
              <Page variant="white" routeName="Sponsors Listing">
                <SponsorsListing />
              </Page>
            }
          />
          <Route
            exact
            path="/booking"
            name="BookingLanding"
            element={
              <Page variant="white" routeName="Booking">
                <BookingLanding />
              </Page>
            }
          />
          <Route
            exact
            path="/pay-online"
            name="PayOnline"
            element={
              <Page variant="white" routeName="Pay Online">
                <PayOnline />
              </Page>
            }
          />
          <Route
            exact
            path="/booking-form"
            name="SponsorLandinBookingForm"
            element={
              // <Page variant="white" routeName="Booking Form">
              //   <BookingForm />
              // </Page>
              <PageForm routeName="Booking Form" />
            }
          />
          <Route
            exact
            path="/sponsor-packages"
            name="SponsorLanding"
            element={
              <Page variant="white" routeName="Sponsor Packages">
                <SponsorLanding />
              </Page>
            }
          />
          <Route
            exact
            path="/sponsor/:id"
            name="SponsorBio"
            element={
              <Page variant="white" routeName="Sponsor Bio">
                <SponsorBio />
              </Page>
            }
          />
          <Route
            exact
            path="/sponsor-booking"
            name="Sponsor Booking form"
            element={<PageForm routeName="Sponsor Form" form="sponsor" />}
          />
          <Route
            exact
            path="/thankyou"
            name="BookingThankyou"
            element={
              <Page variant="white" routeName="Thank you">
                <BookingThankyou />
              </Page>
            }
          />
          <Route
            exact
            path="/agenda-page"
            name="Agenda"
            element={
              <Page variant="white" routeName="Program">
                <AgendaPage />
              </Page>
            }
          />
          <Route
            exact
            path="/attendees"
            name="Attendees"
            element={
              <Page variant="white" routeName="Past Attendees">
                <Attendees />
              </Page>
            }
          />
          <Route
            exact
            path="/privacy-policy"
            name="T&C"
            element={
              <Page variant="white" routeName="Privacy Policy">
                <PrivacyPolicy />
              </Page>
            }
          />
          <Route
            exact
            path="/2024/slideshare"
            name="Slideshare"
            element={
              <Page variant="white" routeName="Slideshare">
                <Slideshare />
              </Page>
            }
          />
          <Route
            exact
            path="/terms-and-conditions"
            name="T&C"
            element={
              <Page variant="white" routeName="Terms and Conditions">
                <TermsAndConditions />
              </Page>
            }
          />
          <Route
            exact
            path="/remind-me-later"
            name="Remind Me Later"
            element={<PageForm routeName="Remind Me Later" form="remind" />}
          />
          <Route path="admin/*" name="Admin" element={<DefaultLayout />} />
          <Route
            exact
            path="*"
            name="Home"
            element={
              <Page routeName="Home">
                <HomeScreen
                  Headerdata={background}
                  SliderData={gallery && gallery}
                  bgVideo={video}
                />
              </Page>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
